html{
    height: 100%;
}
body{
    height: 100%;
}

#root{
    height: 100%;
}

.App {
    height: 100%;
    z-index:100;
}

main {
    height: 100%;
}

.light {
    background-color: #CFD8DC;
    color: #263238;
}

.dark {
    background-color: #263238;
    color: #CFD8DC;
}

/* #2e3131; */