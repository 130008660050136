
#card-set-manager{
    /* position: fixed;
    top:0px;
    left:0px;
    width: 101%;
    height:101%; */
    position: relative;
    /* overflow: visible; */
    /* overscroll-behavior: none; */
    height: 100%;
    padding-top:12px;
    padding-bottom:98px;
}

#card-set-manager.dark {
    background-color: #263238;
    color: #CFD8DC;
}



#card-set-manager .card-set-list>ul {
    padding: 0px;
    list-style-type: none;
}


#add-set {
    margin-bottom:0.5rem;
    height:120px;
}

#add-set>button {
    padding: 1rem;
    border-radius: 50px;
    display: block;
    margin: 4rem auto 1rem auto;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    color: #CFD8DC;
    font-size: 1.25rem;
    font-weight: 600;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.8);
    width: 200px;
    height:64px;
    transform:scale(1);
}

#add-set>button:hover {
    box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.8);
    transform:scale(1.01);
}

#add-set>button:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    transform:scale(0.99);
}








#add-set-modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 910;
    overflow: scroll;
}

#add-set-modal.open {
    display: block;
    z-index: 910;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    backdrop-filter: blur(3px);
}

#add-set-modal.open.light {
    background-color: rgba(38, 50, 56, 0.3);
}

#add-set-modal.open.dark {
    background-color: rgba(207, 216, 220, 0.05);
}

#add-set-modal-window {
    position: relative;
    width: 80%;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15vh;
    padding: 1rem;
    border-radius: 25px;
    max-width: 350px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
}

#add-set-modal-close {
    position: absolute;
    top: -25px;
    right: 5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    color: #CFD8DC;
    transition: 0.5s;
}

/* #add-set-modal-close:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

#add-set-modal-close:active {
    box-shadow: none;
    border: none;
    transition: 0s;
} */













#add-set-modal-close.light {
    color: #263238;
    
}

#add-set-modal-close.dark {
    color: #CFD8DC;
}

#add-set-modal-close:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}

#add-set-modal-close:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}













#add-set-modal-content {
    margin-top: 50px;
}







#addset > .form-group:first-of-type {
    margin-bottom: 45px;
}

#add-set-modal-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
}

#add-set-modal-affirm,
#add-set-modal-cancel {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    height: 64px;
    width: 64px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    transform: scale(1);
}
    

#add-set-modal-affirm:hover,
#add-set-modal-cancel:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);
    transform: scale(1.01);
}

#add-set-modal-affirm:active,
#add-set-modal-cancel:active {
    box-shadow: none;
    transform: scale(0.99);
}