#header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 110vw;
    border: none;
    background-color: #00897B;
    z-index: 900;
    height: 84px;
    box-shadow: -5px 5px 15px rgba(0, 0, 0, 075);
    overflow: hidden;
    color: #CFD8DC;
}


#header.light {
    border-bottom: solid #00695C 3px;
}

#header.dark {
    border-bottom: solid #80CBC4 3px;
}

#header .title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.75rem;
    margin: 0.75rem;
}

#spacer {
    position: relative;
    height: 84px;
    width: 100%;
}

@media (min-width: 350px) {

    #header .title {
        font-size: 0.75rem;
        margin: 0.75rem;
    }
    
}