.signOut {
    display: inline-block;
    position: relative;
    padding: 1rem;
    border-radius: 50px;
    font-size: 0.75rem;
    background-color: transparent;
    color: #e8e8e8;
    display: block;
    /* margin: 2rem auto 1rem auto; */
    border: none;
    font-weight: 700;
    width: 100%;
    height: 100%;
    background-color: #00897B;
    /* box-shadow: 0px 3px 8px rgb(0 0 0 / 50%); */
}

.signOut>span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 50px;
}


.signOut:active {
    transform: scale(1);
    box-shadow: none;
}