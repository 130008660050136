.sign-in-page {
    height: 100%;
    padding-top: 10px;
    overflow: scroll;
}

#sign-in {
    /* border: solid 1px #e8e8e8; */
    border-radius: 25px;
    padding: 1rem;
    margin: 1rem auto 1rem auto;
    max-width: 500px;
}

#sign-in .form-group {
    position: relative;
    padding: 0rem 1rem 1rem 1rem;
}

#sign-in .form-group .validate-text-container {
    position: absolute;
    bottom: -16px;
    left: 15%;
    width: 80%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}

.validate-text-container .validate-text {
    position: relative;
    width: 80%;
    font-size: 0.75rem;
    margin: 0px;
    color: #D50000;
}

#sign-in .form-submit {
    background-color: #616161;
    border: solid 1px #9E9E9E;
    padding: 1rem;
    border-radius: 50px;
    font-size: 1.25rem;
    color: #e8e8e8;
    display: block;
    margin: 2rem auto 1rem auto;
    font-weight: 700;
    width: 100%;
    
    transform: scale(1);
    box-shadow: none;
}

#sign-in .form-submit.active {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    transform: scale(1.1);
    box-shadow: 0px 3px 8px rgb(0 0 0 / 50%);
}

#sign-in .form-submit.active:active {
    transform: scale(1);
    box-shadow: none;
}

#sign-in .signup .light >a {
    text-decoration: underline;
    font-weight: 700;
    color: #00695C;
}

#sign-in .signup .dark >a{
    text-decoration: underline;
    font-weight: 700;
    color: #CFD8DC;
}