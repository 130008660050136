.fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background-color: #00897B;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65); */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
    z-index: 901;
    transform:scale(1.00);
}

.fab.light{
    border: solid #00695C 2px;
}

.fab.dark{
    border: solid #80CBC4 2px;
}

.fab:hover {
    /* box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.75); */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
    transform:scale(1.01);
}

.fab:active {
    /* box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5); */
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    transform:scale(0.99);
}

.fab>img {
    width: 2rem;
}

.menu {
    position: fixed;
    bottom: 48px;
    right: 48px;
    height: 0px;
    width: 0px;
    color: #e8e8e8;
    z-index: 900;
}

.menu>button {
    z-index: 900;
}

.menu-item-constainer {
    z-index: 901;
}

.menu-item {
    text-align: center;
    text-decoration: none;
    line-height: 3.7;
    padding: 0.5rem;
    margin: 1rem;
    height: 48px;
    width: 48px;
    color: #e8e8e8;
    
    background-color: #00897B;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 50px;
    z-index: 901;
    /* transition: 0.5s; */
    transform: scale(0.8);
   
}

.menu-item.light {
    border: solid #00695C 2px;
    

}

.menu-item.dark {
    border: solid #80CBC4 2px;
    
}

.menu-item:hover {
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
    transform:scale(0.81);
}

.menu-item:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    transform:scale(0.79);   
}

/* .menu-item:hover {
    transform: scale(0.82);
    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.5);
    transition: 0s;
}
.menu-item:active {
    transform: scale(0.78);
    transition: 0s;
} */

.menu-item>a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-decoration: none;
    color: #e8e8e8;
    width: 100%;
    height: 100%;
}

.menu-bg {
    position: fixed;
    bottom:0px;
    right:0px;
    width: 100vw;
    height:100vh;
    transition: 0.8s linear;
    /* opacity: 1; */
    visibility: hidden;
}

.menuOpen>.menu-bg{
    position: fixed;
    bottom:0px;
    right:0px;
    width: 100vw;
    height:100vh; 
    backdrop-filter: blur(3px);
    transition: 0.8s linear;
    z-index: 850;
    visibility: visible;
}

.menu-bg.light{
    background-color: rgba(38, 50, 56, 0.3);
}

.menu-bg.dark{
    background-color: rgba(207, 216, 220, 0.05);
}

.menu>:nth-child(2) {
    position: absolute;
    bottom: 20px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0s;
    z-index: 901;
    /* animation: hopOutLink 1s linear 0s; */
}

.menuOpen>:nth-child(2) {
    position: absolute;
    bottom: 20px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0s;
    z-index: 901;
    /* animation: hopInLink 1s linear 0s; */
}

.menu>:nth-child(3) {
    position: absolute;
    bottom: 80px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.15s;
    z-index: 901;
    /* animation: hopOutLink 1s linear 0.15s; */
}

.menuOpen>:nth-child(3) {
    position: absolute;
    bottom: 80px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.15s;
    z-index: 901;
    /* animation: hopInLink 1s linear 0.15s; */
}

.menu>:nth-child(4) {
    position: absolute;
    bottom: 140px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.3s;
    z-index: 901;
    /* animation: hopOutLink 1s linear 0.3s; */
}

.menuOpen>:nth-child(4) {
    position: absolute;
    bottom: 140px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.3s;
    z-index: 901;
    /* animation: hopInLink 1s linear 0.3s; */
}

.menu>:nth-child(5) {
    position: absolute;
    bottom: 200px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.45s;
    z-index: 901;
}

.menuOpen>:nth-child(5) {
    position: absolute;
    bottom: 200px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.45s;
    z-index: 901;
}

.menu>:nth-child(6) {
    position: absolute;
    bottom: 260px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.6s;
    z-index: 901;
}

.menuOpen>:nth-child(6) {
    position: absolute;
    bottom: 260px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.6s;
    z-index: 901;
}

.menu>:nth-child(7) {
    position: absolute;
    bottom: 320px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.75s;
    z-index: 901;
}

.menuOpen>:nth-child(7) {
    position: absolute;
    bottom: 320px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.75s;
    z-index: 901;
}

.menu>:nth-child(8) {
    position: absolute;
    bottom: 380px;
    right: -149px;
    transition: 0.5s;
    transition-delay: 0.9s;
    z-index: 901;
}

.menuOpen>:nth-child(8) {
    position: absolute;
    bottom: 380px;
    right: -50px;
    transition: 0.5s;
    transition-delay: 0.9s;
    z-index: 901;
}

.menu-theme-control.signed-in{
    position: absolute;
    bottom: 405px;
    right: -170px;
    transition: 0.5s;
    transition-delay: 0.9s;
    z-index: 901;
    width: 128px;
    
}

.menuOpen>.menu-theme-control.signed-in{
    position: absolute;
    bottom: 405px;
    right: -34px;
    transition: 0.5s;
    transition-delay: 0.9s;
    z-index: 901;
    width: 128px;
}

.menu-theme-control.signed-out{
    position: absolute;
    bottom: 285px;
    right: -170px;
    transition: 0.5s;
    transition-delay: 0.6s;
    z-index: 901;
    width: 128px;
    
}

.menuOpen>.menu-theme-control.signed-out{
    position: absolute;
    bottom: 285px;
    right: -34px;
    transition: 0.5s;
    transition-delay: 0.6s;
    z-index: 901;
    width: 128px;
}

.menu-link {
    z-index: 900;
}


.menuLogo {
    color: #e8e8e8;
}

.menu-item > a > svg {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%)
}


@media (min-width: 600px) {
    .fab {
        top: 0.5rem;
        box-shadow: none;
        border: solid #00897B 1px;
        transform: scale(1);
        /* border: solid #80CBC4 1px; */
    }

    .fab:hover {
        transform: scale(1.01);
        border: solid #80CBC4 1px;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.8);
    }

    .fab:active {
        transform: scale(0.99);
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    }

    .menu {
        top: 0.5rem;
    }

    .menu-item {
        height: 64px;
        width: 64px;
    }

    .menu>:nth-child(2) {
        position: absolute;
        top: 65px;
        left: 50px;
        transition-delay: 0s;
    }

    .menuOpen>:nth-child(2) {
        position: absolute;
        top: 65px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0s;
    }

    .menu>:nth-child(3) {
        position: absolute;
        top: 145px;
        left: 50px;
        transition-delay: 0.15s;
    }

    .menuOpen>:nth-child(3) {
        position: absolute;
        top: 145px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.15s;
    }

    .menu>:nth-child(4) {
        position: absolute;
        top: 225px;
        left: 50px;
        transition-delay: 0.3s;
    }

    .menuOpen>:nth-child(4) {
        position: absolute;
        top: 225px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.3s;
    }

    .menu>:nth-child(5) {
        position: absolute;
        top: 305px;
        left: 50px;
        transition-delay: 0.45s;
    }

    .menuOpen>:nth-child(5) {
        position: absolute;
        top: 305px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.45s;
    }

    .menu>:nth-child(6) {
        position: absolute;
        top: 385px;
        left: 50px;
        transition-delay: 0.6s;
    }

    .menuOpen>:nth-child(6) {
        position: absolute;
        top: 385px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.6s;
    }

    .menu>:nth-child(7) {
        position: absolute;
        top: 465px;
        left: 50px;
        transition-delay: 0.75s;
    }

    .menuOpen>:nth-child(7) {
        position: absolute;
        top: 465px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.75s;
    }

    .menu>:nth-child(8) {
        position: absolute;
        top: 545px;
        left: 50px;
        transition-delay: 0.9s;
    }

    .menuOpen>:nth-child(8) {
        position: absolute;
        top: 545px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.9s;
    }

    .menu>:nth-child(9) {
        position: absolute;
        top: 625px;
        left: 50px;
        transition-delay: 0.75s;
    }

    .menuOpen>:nth-child(9) {
        position: absolute;
        top: 625px;
        left: -60px;
        transition: 0.5s;
        transition-delay: 0.75s;
    }

    .menu-theme-control.signed-in{
        position: absolute;
        top: 565px;
        left: 50px;
        transition: 0.5s;
        transition-delay: 0.9s;
        z-index: 901;
        width: 128px;
    }
    
    .menuOpen>.menu-theme-control.signed-in{
        position: absolute;
        top: 570px;
        left: -90px;
        transition: 0.5s;
        transition-delay: 0.9s;
        z-index: 901;
        width: 128px;
    }
    
    .menu-theme-control.signed-out{
        position: absolute;
        bottom: 285px;
        right: -170px;
        transition: 0.5s;
        transition-delay: 0.6s;
        z-index: 901;
        width: 128px;
        
    }
    
    .menuOpen>.menu-theme-control.signed-out{
        position: absolute;
        bottom: 285px;
        right: -34px;
        transition: 0.5s;
        transition-delay: 0.6s;
        z-index: 901;
        width: 128px;
    }
}

@keyframes hopInLink {
    0%{right:-149px}
    15%{right:-20px}
    30%{right:-55px}
}

@keyframes hopOutLink {
    0%{right:-55px}
    15%{right:-20px}
    30%{right:-149px}
}

/* @media (min-width: 850px) {

    .fab {
        display: none;
    }

    .menu-item {
        display: block;
        text-align: center;
        line-height: 2.5;
        width: auto;
        height: auto;
        text-decoration: none;
        margin: 1rem;
        color: #BDC3C7;
        background-color: inherit;
        z-index: 901;
        border: none;
        box-shadow: none;
        font-size: large;
    }

    .menu>:nth-child(1) {
        position: absolute;
        top: 0px;
        left: -335px;
        z-index: 899;
    }

    .menu>:nth-child(2) {
        position: absolute;
        top: 0px;
        left: -195px;
    }

    .menu>:nth-child(3) {
        position: absolute;
        top: 0px;
        left: -49px;
    }

    .menu>:nth-child(4) {
        position: absolute;
        top: 0px;
        left: -49px;
    }

    .menu {
        top: 1rem !important;
        right: 8vw;
    }
    
} */