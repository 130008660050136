.card-small {
    position: relative;
    display: inline-block;
    height: 375px;
    width: 300px;
    margin: 10x;
    margin-right: auto;
    margin-left: auto;
}

.editcard {
    position: relative;
    height: 100%;
    margin: 0px 15px 0px 15px;
}

.edit-card-form {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    height: 100%;
    perspective: 1000px;
    border-radius: 25px;
    box-shadow: 6px 6px 8px rgb(0,0,0,0.8);
}

.edit-card-form-2 {
    height: 75%;
    width: 90%;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%, 0%);
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* .flipped .edit-card-form-2 {
    transform: rotateY(180deg) translate(50%, 0%);
} */

.edit-card-front,
.edit-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 25px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.edit-card-back {
    transform: rotateY(180deg);
}

.flipped.edit-card-front{
    transform: rotateY(180deg);
}

.flipped.edit-card-back{
    transform: rotateY(0deg);
}

.edit-card-front>textarea,
.edit-card-back>textarea {
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60%;
    width: 80%;
    border-radius: 25px;
    font-size: 16px;
    padding: 1rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    resize: none;
    border-color: #80CBC4;
}

.editCardNotInFocus {
    position: absolute;
    top: 24px;
    left: 10%;
    transform: translate(0%, 0%);
    font-size: 22px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.5s linear;
    font-weight: 500;
}

.editCardInFocus {
    position: absolute;
    top: -7px;
    left: 5%;
    transform: translate(0%, 0%);
    font-size: 14px;
    font-weight: 900;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.5s linear;
}

.edit-card-switch-container {
    display: inline;
    position: absolute;
    right: 108px;
    bottom: 43px;
    border-radius: 50px;
    user-select: none;
    overflow: hidden;
    transform: translate(50%, 0%);
}

.edit-card-switch {
    position: relative;
    width: 96px;
    height: 48px;
    background-color: #CFD8DC;
    border: solid #80CBC4 1px;
    border-radius: 50px;
    overflow: hidden;
}

.edit-card-switch-toggle {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0%, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
}

.edit-card-switch-toggle-off {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(-3%, -50%);
    width: 99px;
    height: 48px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    transition: 0.25s linear;
}

.edit-card-switch-toggle-on {
    position: absolute;
    top: 50%;
    left: 46px;
    transform: translate(0%, -50%);
    width: 96px;
    height: 48px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    transition: 0.25s linear;
}

.edit-card-switch-front {
    position: absolute;
    top: 0%;
    right: 7%;
    transform: translate(0%, -12%);
    color: #CFD8DC;
    font-weight: bold;
    /* z-index: 985; */
}

.edit-card-switch-back {
    position: absolute;
    top: -6%;
    left: 7%;
    transform: translate(0%, 12%);
    border: solid #80CBC4 1px;
    background-color: #00897B;
    font-weight: bold;
    /* z-index: 945; */
}

.edit-card-switch-back-off {
    position: absolute;
    top: -6%;
    left: 7%;
    transform: translate(0%, 12%);
    border: none;
    color:#00897B;
    background-color: #CFD8DC;
    font-weight: bold;
    transition: 0.25s linear;
}

.edit-card-switch-back-on {
    position: absolute;
    top: -6%;
    left: 7%;
    transform: translate(-130%, 12%);
    border: none;
    color:#00897B;
    background-color: #CFD8DC;
    font-weight: bold;
    transition: 0.25s linear;
}

.update-card {
    position: absolute;
    left: 36px;
    bottom: 45px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    width: 48px;
    height: 48px;
    color: #CFD8DC;
    border-radius: 50px;
    /* z-index: 800; */
    transform: translate(50%, 0%) scale(1);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
}

.update-card:hover{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: translate(50%, 0%) scale(1.01);
}

.update-card:active{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: translate(50%, 0%) scale(1);
}

.next-card {
    position: absolute;
    right: 5px;
    bottom: 45px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    width: 48px;
    height: 48px;
    color: #CFD8DC;
    border-radius: 50px;
    /* z-index: 800; */
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

.next-card:hover{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: scale(1.01);
}

.next-card:active{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

.prev-card {
    position: absolute;
    left: 5px;
    bottom: 45px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    width: 48px;
    height: 48px;
    color: #CFD8DC;
    border-radius: 50px;
    /* z-index: 800; */
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

.prev-card:hover{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: scale(1.01);
}

.prev-card:active{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

.card-info {
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translate(-50%,0%);
}











.delete-card {
    position: absolute;
    top: 5px;
    right: 5px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    width: 48px;
    height: 48px;
    color: #CFD8DC;
    border-radius: 50px;
    /* z-index: 800; */

    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);

}

.delete-card:hover {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: scale(1.01);
}

.delete-card:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

/* #delete-card-modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 800;
}

#delete-card-modal.open {
    display: block;
    position: fixed;
}



#delete-card-modal-window {
    position: fixed;
    top: 30vh;
    left: 50%;
    height: 30vh;
    min-width: 250px;
    max-width: 500px;
    padding: 1rem;
    transform: translate(-50%, -0%);
    border: solid 1px #e8e8e8;
    border-radius: 25px;
    background-color: #2e3131;
    z-index: 800;
}

#delete-card-modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #e8e8e8;
    width: 50px;
    height: 50px;
    padding: 0.5rem;
}

#delete-card-modal-content {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 70%;
    width: 100%;
}

#delete-card-modal-content>p {
    text-align: center;
}

#delete-card-modal-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    position: absolute;
    width: 100%;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

#delete-card-modal-affirm {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #e8e8e8;
    padding: 1rem;
    margin: 1em;
    width: 125;
    height: 48px;
    font-size: 1rem;
}

#delete-card-modal-cancel {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #e8e8e8;
    padding: 1rem;
    margin: 1em;
    width: 125;
    height: 48px;
    font-size: 1rem;
} */










@keyframes slide-in-right {
    from {
        right: 0px;
    }

    to {
        right: 100px;
    }
}

@keyframes slide-out-right {
    from {
        right: 0px;
    }

    to {
        right: 100px;
    }
}

@keyframes slide-in-left {
    from {
        left: 0px;
    }

    to {
        left: 100px;
    }
}

@keyframes slide-out-left {
    from {
        left: 0px;
    }

    to {
        left: 100px;
    }
}