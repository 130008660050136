.card-set {
    position: relative;
    border: solid 1px #CFD8DC;
    border-radius: 25px;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
}

.card-set.light {
    border: solid 1px #263238;
    background-color: #4DB6AC;
}

.card-set.dark {
    border: solid 1px #CFD8DC;
    background-color: #004D40;
}



.card-set-list {
    padding-bottom: 98px;
}

.card-set>div>div>div {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card-set>details>summary {
    list-style: '+';
    list-style-position: inside;
}

.card-set-controls {
    position: relative;
    width: 50%;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
}

.delete-card-set {
    position: absolute;
    top: 5px;
    right: 9px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    width: 48px;
    height: 48px;
    color: #CFD8DC;
    border-radius: 50px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: scale(1);

}

.delete-card-set:hover {
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.7);
    transform: scale(1.01);
}

.delete-card-set:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(0.99);
}

.accordion-title {
    position: relative;
}

.accordion-toggle {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 38px;
    left: 20px;
    transform: translate(0px, 0%);

}

.accordion-toggle>svg {
    transition: 0.35s;
}

.accordion-toggle-icon {
    width: 50px;
    height: 50px;
    transform: rotate(-180deg);
}

.accordion-toggle-open {
    transform: rotate(-90deg);
}

.accordion-content {
    height: 0px;
    transition: 1s;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
}

.accordion-open {

    transition: 1s;
    width: 100%;
    height: 400px;
}

.cardset-controls {
    display: flex;
    justify-content: space-evenly;
}

.add-card,
.edit-card-set-info {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    height: 54px;
    width: 108px;
    color: #CFD8DC;
    border-radius: 50px;
    padding: 1rem;
    font-size: 1rem;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    transform: scale(1);
}

.add-card:hover,
.edit-card-set-info:hover {
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.7);
    transform: scale(1.01);
}

.add-card:active,
.edit-card-set-info:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.7);
    transform: scale(0.99);
}

#add-card-modal,
#delete-cardset-modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 910;
    overflow: scroll;
    backdrop-filter: blur(3px);
}

#add-card-modal.open,
#delete-cardset-modal.open {
    display: block;
}

#add-card-modal.open.light,
#delete-cardset-modal.open.light {
    background-color: rgba(38, 50, 56, 0.3);
}

#add-card-modal.open.dark,
#delete-cardset-modal.open.dark {
    background-color: rgba(207, 216, 220, 0.05);
}

#add-card-modal-window {
    position: relative;
    width: 80%;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    border-radius: 25px;
    max-width: 350px;
    height: 450px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
}

#delete-cardset-modal-window {
    position: relative;
    width: 80%;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15vh;
    padding: 1rem;
    border-radius: 25px;
    max-width: 350px;
    height: 250px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);

}

#add-card-modal-close,
#delete-cardset-modal-close {
    position: absolute;
    top: -25px;
    right: 5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    color: #CFD8DC;
    transition: 0.5s;
}


#add-card-modal-close.light,
#delete-cardset-modal-close.light {
    color: #263238;

}

#add-card-modal-close.dark,
#delete-cardset-modal-close.dark {
    color: #CFD8DC;
}


#add-card-modal-close:hover,
#delete-cardset-modal-close:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}

#add-card-modal-close:active,
#delete-cardset-modal-close:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}










#add-card-modal-content,
#delete-cardset-modal-content {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 70%;
    width: 100%;
}

#delete-cardset-modal-content {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 70%;
    width: 100%;
}

#delete-cardset-modal-content>p {
    text-align: center;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.5rem;
}

#add-card-modal-action,
#delete-cardset-modal-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    position: absolute;
    width: 100%;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

#add-card-modal-affirm,
#delete-cardset-modal-affirm {

    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    font-size: 16px;
    margin: 1rem 0.5rem 1rem 0.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    transform: scale(1);

}

#add-card-modal-affirm {
    height: 64px;
    width: 64px;
}

#delete-cardset-modal-affirm {
    height: 48px;
    width: 175px;
}


#add-card-modal-affirm:hover,
#delete-cardset-modal-affirm:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);
    transform: scale(1.01);
}

#add-card-modal-affirm:active,
#delete-cardset-modal-affirm:active {
    box-shadow: none;
    transform: scale(0.99);
}



#add-card-modal-cancel,
#delete-cardset-modal-cancel {

    margin: 1rem 0.5rem 1rem 0.5rem;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;

    font-size: 1rem;
    margin: 1rem 0.5rem 1rem 0.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    transform: scale(1);
}

#add-card-modal-cancel {
    height: 64px;
    width: 64px;
}

#delete-cardset-modal-cancel {
    height: 48px;
    width: 175px;
}

#add-card-modal-cancel:hover,
#delete-cardset-modal-cancel:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);
    transform: scale(1.01);
}

#add-card-modal-cancel:active,
#delete-cardset-modal-cancel:active {
    box-shadow: none;
    transform: scale(0.99);
}

#addcard {
    position: relative;
    height: 100%;
    margin-top: 30px;
}












/* .card-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 75%;
    border: solid #80CBC4 1px;
    display: block;
}

.card {
    border: solid #80CBC4 1px;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    display: block;
}

.flipped.card {
    transform: rotateY(180deg);
}

.card-front {
    position: absolute;
    top: 0px;
    left: 0px;
    border: solid #80CBC4 1px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;

}

.card-back {
    position: absolute;
    top: 0px;
    left: 0px;
    border: solid #80CBC4 1px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
}

.card-back {
    transform: rotateY(180deg);
}


 */






















.add-card-form-container {
    position: absolute;
    top:0px;
    left:50%;
    transform: translate(-50%,0%);
    height: 100%;
    perspective: 1000px;
    height: 95%;
    perspective: 1000px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 25px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
}

.add-card-form {
    position: relative;
    height: 80%;
    width: 90%;
    transform: translate(5%, 0%);
    transition: transform 0.8s;
    transform-style: preserve-3d;
    z-index: 800;
    display: inline-block;
}

.flipped.add-card-front {
    transform: rotateY(180deg) translate(-5%, 0%);
    transform-style: preserve-3d;
   
}

.flipped.add-card-back{
    transform: rotateY(0deg) translate(-5%, 0%);
    transform-style: preserve-3d;
   
}

.add-card-front {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 25px;
    /* background-color: #5C6BC0; */
    margin: 0px;
    z-index: 750;
    display: inline-block;
    transform-style: preserve-3d;
    transition: transform 0.8s;
}

.add-card-back {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    /* background-color: #42A5F5; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 750;
    display: inline-block;
    transform-style: preserve-3d;
    transition: transform 0.8s;
}

.add-card-back {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
}

.add-card-front>textarea {
    /* background-color: #5C6BC0; */
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 70%;
    width: 80%;
    border-radius: 25px;
    font-size: 16px;
    padding: 1rem;
    resize: none;
}

.add-card-back>textarea {
    /* background-color: #42A5F5; */
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-45%, 0%);
    height: 70%;
    width: 80%;
    border-radius: 25px;
    font-size: 16px;
    padding: 1rem;
    resize: none;
    z-index: 50;
}

.noFocus {
    font-size: 22px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.5s linear;
    font-weight: 500;
    position: absolute;
    z-index: 100;
}

.add-card-front .noFocus{
    transform: translate(25px,50px);
}

.add-card-back .noFocus{
    transform: translate(40px,50px);
}

.inFocus {
    font-size: 14px;
    font-weight: 900;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: 0.5s linear;
    position: absolute;
    transform: translate(15px,20px);
    z-index: 100;
}

.add-card-front .inFocus{
    transform: translate(15px,20px);
}

.add-card-back .inFocus{
    transform: translate(25px,20px);
}
























.switch-container {
    display: inline;
    position: absolute;
    left: 50%;
    bottom: 24px;
    z-index: 970;
    border-radius: 50px;
    user-select: none;
    overflow: hidden;
    transform: translate(-50%, 0%);
}

.switch {
    position: relative;
    width: 96px;
    height: 48px;
    background-color: #CFD8DC;
    border: solid #80CBC4 1px;
    border-radius: 50px;
    overflow: hidden;

}

.switch-toggle {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0%, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50px;

    border: solid #80CBC4 1px;
    background-color: #00897B;
}

.switch-toggle-off {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(-3%, -50%);
    width: 99px;
    height: 48px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    /* border: solid #e8e8e8 3px; */
    border-radius: 50px;

    transition: 0.25s linear;


}

.switch-toggle-on {
    position: absolute;
    top: 50%;
    left: 46px;
    transform: translate(0%, -50%);
    width: 96px;
    height: 48px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    /* border: solid #e8e8e8 3px; */
    border-radius: 50px;

    transition: 0.25s linear;


}

.switch-front {
    position: absolute;
    top: 1%;
    right: 6%;
    transform: translate(0%, -12%);
    color: #CFD8DC;
    font-weight: bold;

}

.switch-back-off {
    position: absolute;
    top: -4%;
    left: 8%;
    transform: translate(0%, 12%);
    color: #00897B;
    font-weight: bold;
    transition: 0.3s;

}

.switch-back-on {
    position: absolute;
    top: -4%;
    left: -40%;
    transform: translate(0%, 12%);
    color: #00897B;
    font-weight: bold;
    transition: 0.3s;

}

.switch-toggle-on>.switch-back>p {
    position: absolute;
    top: -4%;
    left: -40%;
    transform: translate(0%, 12%);
    color: #00897B;
    font-weight: bold;
    transition: 0.5;

}

.cardList {
    position: relative;
    height: 400px;
    /* width: 1500px; */
    /* transform: translate(-608px, 0px); */
    overflow: hidden;
    padding-top: 10px;
    z-index: 801;
}

#edit-set-modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 910;
    overflow: scroll;
    backdrop-filter: blur(3px);
}

#edit-set-modal.open {
    display: block;
}

#edit-set-modal.open.light{
    background-color: rgba(38, 50, 56, 0.3);
}

#edit-set-modal.open.dark{
    background-color: rgba(207, 216, 220, 0.05);
}

#edit-set-modal-window {
    position: relative;
    width: 80%;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15vh;
    padding: 1rem;
    border-radius: 25px;
    max-width: 350px;
    height: 350px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
}

#edit-set-modal-close {
    position: absolute;
    top: -25px;
    right: 5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    /* border: solid 1px #e8e8e8; */
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    /* color: #e8e8e8; */
    transition: 0.5s;
    z-index: 800;
}

#edit-set-modal-close:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

#edit-set-modal-close:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}



#edit-set-modal-content {
    margin-top: 30px;
}

#edit-set-modal-action {
    display: flex;
    justify-content: space-evenly;
}

#edit-set-modal-affirm {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    width: 64px;
    height: 64px;
    transform: scale(1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

#edit-set-modal-cancel {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    width: 64px;
    height: 64px;
    transform: scale(1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

#edit-set-modal-affirm:hover,
#edit-set-modal-cancel:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);
    transform: scale(1.01);
}

#edit-set-modal-affirm:active,
#edit-set-modal-cancel:active {
    box-shadow: none;
    transform: scale(0.99);
}

#editset>.form-group:first-of-type {
    margin-bottom: 40px;

}






#delete-card-modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 910;
    backdrop-filter: blur(3px);
}

#delete-card-modal.open {
    display: block;
    position: fixed;
}


#delete-card-modal.open.light{
    background-color: rgba(38, 50, 56, 0.3);
}

#delete-card-modal.open.dark{
    background-color: rgba(207, 216, 220, 0.05);
}


#delete-card-modal-window {
    position: fixed;
    top: 30vh;
    left: 50%;
    height: 30vh;
    min-width: 250px;
    max-width: 500px;
    padding: 1rem;
    transform: translate(-50%, -0%);
    /* border: solid 1px #e8e8e8; */
    border-radius: 25px;
    /* background-color: #2e3131; */
    z-index: 800;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
    
}

/* #delete-card-modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #e8e8e8;
    width: 50px;
    height: 50px;
    padding: 0.5rem;
} */

#delete-card-modal-content {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 70%;
    width: 100%;
}

#delete-card-modal-content>p {
    text-align: center;
}

#delete-card-modal-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    position: absolute;
    width: 100%;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

#delete-card-modal-affirm {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    margin: 1em;
    width: 125;
    height: 48px;
    font-size: 1rem;
}

#delete-card-modal-cancel {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    border-radius: 50px;
    color: #CFD8DC;
    padding: 1rem;
    margin: 1em;
    width: 125;
    height: 48px;
    font-size: 1rem;
}





#delete-card-modal-close {
    position: absolute;
    top: -25px;
    right: 5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    color: #CFD8DC;
    transition: 0.5s;
}

#delete-card-modal-close:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

#delete-card-modal-close:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}