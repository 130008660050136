.email-input {
    position: relative;
    margin: 0rem;
    padding: 1.5rem;
}

.email-input label {
    position: absolute;
    bottom:0px;
    left:10%;
    z-index: 400;
    font-size: 1.25rem;
    font-weight: 400;
    transition: 1s;
    transform: scale(1);   
}

.email-input > .focused {
    font-weight: 700;
    transform: translate(-20%,-110%) scale(0.7);
    transition: 1s;
}

.email-input input {
    position: absolute;
    bottom:0px;
    left:50%;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    border-bottom-style: solid; 
    border-bottom-width:1px;
    font-size: 1rem;
    z-index: 840;
    width: 80%;
    transform: translate(-50%,0%);
    font-size: 1.25rem;
}

.dark.email-input input {
    border-bottom-color: #CFD8DC;
    color: #CFD8DC;
}

.light.email-input input {
    border-bottom-color: #263238;
    color: #000000;
}

.email-input input:focus {
    outline: none;
}