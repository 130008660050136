.account-page {
    height: 100%;
   

}

.account-info {
    position: relative;
    border: solid #CFD8DC 0px;
    margin-right: auto;
    margin-left: auto;
    max-width: 450px;
    z-index: 700;
    overflow: hidden;
    height: 100%;
}

.account-field {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 0rem 1rem 0rem;
}

.account-field-light{
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 0rem 1rem 0rem;
    background-color: #CFD8DC;
}

.account-field-dark{
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 0rem 1rem 0rem;
    background-color: #263238;
}

.account-input {
    display: inline-block;
    width: 80%;
    height: 100%;
}

.account-input-confirm {
    width: 80%;
    transform: translate(-7%, 0%);
}

.account-field .validate-text {
    position: absolute;
    bottom: -16px;
    left: 15%;
    font-size: 0.75rem;
    margin: 0px;
    color: #D50000;
    
}

.account-action {
    /* border: solid white 1px; */
    position: relative;
    display: inline-block;
    width: 10%;
    height: 48px;
  
}

.account-edit {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(40%, 70%) scale(1);
    border-radius: 50px;
    width: 48px;
    height: 48px;
    background-color: #00897B;
    /* color: #e8e8e8; */
    z-index: 800;
}

.light.account-edit {

    border: solid #00695C 2px;
    

}

.dark.account-edit {

    border: solid #80CBC4 1px;
    

}


.account-edit:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
    transform:translate(40%, 70%) scale(1.01);
}
.account-edit:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    transform:translate(40%, 70%) scale(0.99);
}

.account-save {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(40%, 70%) scale(1);
    border-radius: 50px;
    width: 48px;
    height: 48px;
    background-color: #616161;
    border: solid 1px #9E9E9E;
    color: #CFD8DC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.account-save:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
    transform:translate(40%, 70%) scale(1.01);
}
.account-save:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
    transform:translate(40%, 70%) scale(0.99);
}

.light .account-action .active {
    border: solid #00695C 2px;
    background-color: #00897B;
}

.dark .account-action .active {
    border: solid #80CBC4 1px;
    background-color: #00897B;
}


#delete-account {
    padding: 1rem;
    border-radius: 50px;
    font-size: 1.25rem;
    color: #D32F2F;
    background-color: transparent;
    border: solid 3px #D32F2F;
    margin-top: 1rem;
    transition: 0.5s;
    font-weight: bolder;
}

#delete-account:hover {
    background-color: #D32F2F;
    border: solid 3px #B71C1C;
    color: #CFD8DC;
    transition: 0.5s;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}

.reauthModal {
    display: none;
    z-index: 0;
}

.reauthModal.open {
    display: block;
    z-index: 910;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.9); */
    backdrop-filter: blur(3px);
    overflow: scroll;
}

.reauthModal.open.light{
    background-color: rgba(38, 50, 56, 0.3);
}

.reauthModal.open.dark{
    background-color: rgba(207, 216, 220, 0.05);
}

.reauthModal-content {
    position: relative;
    /* background-color: #263238; */
    width: 80%;
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1rem 2rem 1rem;
    border-radius: 25px;
    max-width: 350px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
}

.reauthModal-content.light {
    background-color: #CFD8DC;
    border: solid 1px #ECEFF1;
}

.reauthModal-content.dark {
    background-color: #263238;
    border: solid 1px rgba(0, 0, 0, 0.3);
}

.reauth-form {
    margin-top: 24px;
}

#reauth-submit {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    color: #CFD8DC;
    font-weight: bold;
    font-size: large;
    width: 120px;
    height: 54px;
    transform: scale(1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

#reauth-submit:hover{
    transform: scale(1.01);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);
}

#reauth-submit:active{
    transform: scale(0.99);
    box-shadow: none;
}

.close-modal {
    position: absolute;
    top:-25px;
    right:5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    /* border: solid 1px #e8e8e8; */
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    color: #CFD8DC;
    transition: 0.5s;
}

.close-modal:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}

.close-modal:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}

.form-group {
    margin: 1rem 1rem 1rem 1rem;
}

.form-group>button {
    display: block;
    margin: 2rem auto 1rem auto;
    padding: 1rem;
    width: 95px;
    border: solid 1px #CFD8DC;
    border-radius: 50px;
    background-color: transparent;
    color: #CFD8DC;
}

.hide-confirm {
    height: 0px;
    transition: 1s;
    
}

.open-confirm {
    height: 48px;
    transition: 1s;
}

.deleteModal {
    display: none;
    z-index: 0;
}

.deleteModal.open {
    display: block;
    z-index: 910;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* background-color: transparent; */
    backdrop-filter: blur(3px);
    overflow: scroll;
}

.deleteModal.open.light{
    background-color: rgba(38, 50, 56, 0.3);
}

.deleteModal.open.dark{
    background-color: rgba(207, 216, 220, 0.05);
}

.deleteModal-content, .verifyModal-content {
    position: relative;
    background-color: #263238;
    width: 80%;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    border-radius: 25px;
    max-width: 350px;
    box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.8);
}

.deleteModal-content.light, .verifyModal-content.light {
    background-color: #CFD8DC;
    border: solid 1px #ECEFF1;
}

.deleteModal-content.dark, .verifyModal-content.dark {
    background-color: #263238;
    border: solid 1px rgba(0, 0, 0, 0.3);
}

.close-delete-modal, .close-reauth-modal, .close-verify-modal {
    position: absolute;
    top:-25px;
    right:5px;
    margin: 2rem auto 1rem auto;
    padding: 0rem;
    width: 48px;
    height: 48px;
    border: solid 0px rgba(0, 0, 0, 0.5);
    /* border: solid 1px #e8e8e8; */
    border-radius: 50px;
    background-color: transparent;
    font-size: 1rem;
    
    transition: 0.5s;
}

.close-delete-modal.light, .close-reauth-modal.light, .close-verify-modal.light {
    color: #263238;
    
}

.close-delete-modal.dark, .close-reauth-modal.dark, .close-verify-modal.dark {
    color: #CFD8DC;
}

.close-delete-modal:hover, .close-reauth-modal:hover, .close-verify-modal:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}

.close-delete-modal:active, .close-reauth-modal:active, .close-verify-modal:active {
    box-shadow: none;
    border: none;
    transition: 0s;
}

.deleteModal-text {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}



.deleteModal-action>button {
    display: block;
    margin: 0rem auto 1rem auto;
    padding: 1rem;
    width: 80%;
   
    border-radius: 50px;
    font-size: 1rem;
    
}

/* .go-back-btn {
   
    background-color: #00897B;
    font-weight: bolder;
    color: #CFD8DC;
} */

.go-back-btn.light {
    background-color: #00897B;
    border: solid #00695C 2px;
    color: #CFD8DC;
    font-weight: bolder;
}

.go-back-btn.dark {
    background-color: #00897B;
    border: solid #80CBC4 2px;
    color: #CFD8DC;
    font-weight: bolder;
    transform: scale(1);
    
}

.go-back-btn:hover{
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.8);
    transition: 0.5s;
    transform: scale(1.01);
}

.go-back-btn:active{
    box-shadow: none;
    transition: 0s;
    transform: scale(0.99);
}

.delete-account-btn {
    background-color: transparent;
    border: solid 3px #D32F2F;
    color: #D32F2F;
    font-weight: bolder;
    transition: 0.5s;
}

.delete-account-btn:hover {
    color: #CFD8DC;
    background-color: #D32F2F;
    transition: 0.5s;
    border: solid 3px #B71C1C;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.8);
}

.delete-account-btn:active {
    color: #263238;
    background-color: #D32F2F;
    transition: 0.5s;
    border: solid 3px #B71C1C;
}

.email-verified {
    margin-left: 15%;
    margin-right: auto;
    margin-top: -0.5rem;
    width: 70%;
}

.verified {
    color: green;
}

.unverified {
    display: inline;
    color: #D32F2F;
    margin-right: 1rem;
    font-size: 14px;
    font-weight: 600;
}

#verify-email-light {
    margin-top: 5px;
    border-radius: 25px;
    border: solid 1px #00695C;
    color: #00695C;
    background-color: transparent;
    transition: 0.5s;
}

#verify-email-dark {
    margin-top: 5px;
    border-radius: 25px;
    border: solid 1px #CFD8DC;
    color: #CFD8DC;
    background-color: transparent;
    transition: 0.5s;
}

#verify-email-light:hover {
    border: solid #00695C 1px;
    background-color: #00897B;
    transition: 0.5s;
    color: #CFD8DC;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

#verify-email-dark:hover {
    border: solid #80CBC4 1px;
    background-color: #00897B;
    transition: 0.5s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

#verify-email-light:active {
    border: solid #00695C 1px;
    background-color: #00897B;
}

#verify-email-dark:active {
    border: solid #80CBC4 1px;
    background-color: #00897B;
}


.verifyModal {
    display: none;
    z-index: 910;
}

.verifyModal.open {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    /* background-color: rgba(0, 0, 0, 0.9); */
    overflow: scroll;
}

.verifyModal.open.light {
    background-color: rgba(38, 50, 56, 0.3);
}

.verifyModal.open.dark {
    background-color: rgba(207, 216, 220, 0.05);
}

.verifyModal-text {
    margin-top: 50px;
    text-align: center;
}

.verifyModal-action {
    display: flex;
    justify-content: center;
}

.verifyModal-action>button {
    display: block;
    margin: 2rem auto 1rem auto;
    padding: 1rem;
    width: 120px;
    border: solid 1px #CFD8DC;
    border-radius: 50px;
    border: solid #80CBC4 1px;
    background-color: #00897B;
    font-size: 1rem;
    color: #CFD8DC;
    font-weight: bold;
}